import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    name: String,
    properties: Object,
  }

  connect() {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return;
    }

    Heya.trackEvent(this.nameValue, this.propertiesValue)
  }
}