import ahoy from 'ahoy.js';

ahoy.configure({
  visitsUrl: '/heya/visits',
  eventsUrl: '/heya/events'
})

class Heya {
  trackEvent(eventName, customProperties = {}) {
    let defaultProperties = {}

    switch (eventName) {
      case 'pageview':
        defaultProperties =
        {
          url: window.location.href,
          page: window.location.pathname
        }

        break;
    }

    ahoy.track(
      eventName,
      Object.assign(
        defaultProperties,
        customProperties
      )
    )
  }
}

window.Heya = new Heya();